<template>
  <Loading
    :active="loading.isLoading"
    :can-cancel="true"
    :is-full-page="loading.fullPage"
    color="#3E69F6"
  >
  </Loading>
</template>

<script>
// import { NEXTSHOP_APPID } from "../../.env";
import { MerchantService } from "../../services/merchant.service";
import { AuthService } from "../../services/auth.service";

export default {
  data() {
    return {
      loading: {
        isLoading: true,
        fullPage: true,
      },
      aliasMerchant: this.$route.params.alias,
      token: this.$cookies.get("token"),
      merchantId: "",
      appId: this.$route.query.appId ? this.$route.query.appId : "",
      redirectUri: this.$route.query.redirectUri
        ? this.$route.query.redirectUri
        : "",
    };
  },
  created() {
    this.authorize();
  },
  methods: {
    async getListMerchant() {
      try {
        const response = await MerchantService.getList(this.token);
        const merchant = response.data.find((item) => item.aliasMerchant);
        const merchantId = merchant.merchantId;
        return merchantId;
      } catch (error) {
        console.log(error);
      }
    },
    async authorize() {
      try {
        let merchantId = await this.getListMerchant();
        merchantId = merchantId !== "" ? merchantId : AuthService.getCookie("merchantId");
        const response = await AuthService.authorize(
          this.token,
          merchantId,
          this.appId,
          decodeURIComponent(this.redirectUri)
        );
        if (response.code === "SUCCESS") {
          console.log("Success");
          if (response.data.authorizedCallbackUri.includes("?")) {
            window.location.href = `${response.data.authorizedCallbackUri}&authorizationCode=${response.data.authorizationCode}&userId=${response.data.userId}&userType=${response.data.userType}&merchantId=${response.data.merchantId}`;
          } else {
            window.location.href = `${response.data.authorizedCallbackUri}?authorizationCode=${response.data.authorizationCode}&userId=${response.data.userId}&userType=${response.data.userType}&merchantId=${response.data.merchantId}`;
          }
        } else if (response.data.code === "APPLICATION_NOT_FOUND") {
          alert("APPLICATION_NOT_FOUND");
        } else {
          alert(response.data.code);
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading.isLoading = false;
      }
    },
  },
};
</script>

<style>
.bg-main {
  display: none;
}
</style>
